import { REVALIDATE_SETTING } from "@/setting/setting";
import type { GetStaticProps } from "next";
import router from "next/router";
import { useEffect } from "react";
import styles from "./styles.module.scss";
// import { THEME_SETTING } from '../../setting/setting'

const Custom404 = ({ redirect = false, locale }: any) => {
	//   const { t } = useTranslation()

	useEffect(() => {
		if (redirect) {
			router.push("/");
		}
	}, []);

	if (!redirect) {
		return (
			<main className={styles.page404}>
				<div className={styles.info}>
					<h1>페이지를 찾을 수 없습니다.</h1>
					<p>
						죄송합니다, 이 페이지는 더 이상 저희 웹사이트에서 이용하실 수
						없습니다.
					</p>
					<button onClick={(_e) => router.push("/")}>
						메인 페이지로 돌아가기
					</button>
				</div>
			</main>
		);
	}

	return <></>;
};

// withHeader dispara bug de fs
const getStaticProps: GetStaticProps = () =>
	//   withHeader(async (props: any) =>
	{
		return {
			props: {
				// locale: 'kr',
			},
			revalidate: REVALIDATE_SETTING,
		};
	};
//   )

export { getStaticProps as GetStaticProps, Custom404 };
